<template>
  <div
    class="flex-column mt-1 address-detail address-edit-container"
    v-if="internalValue"
  >
    <h2 class="mb-5" v-html="titleSection"></h2>

    <v-form
      @submit.prevent.stop="saveAddress"
      ref="form"
      class="add-address-form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-alert border="left" type="error" v-if="error">{{ $t(error) }}</v-alert>
      <v-row class="grey lighten-2 rounded">
        <v-col cols="12" sm="3">
          <!-- PROVINCE -->
          <v-combobox
            :label="$t('register.user.province')"
            v-model="internalValue.province"
            :rules="[requiredRule()]"
            :items="getProvincesList(internalValue.province)"
            append-icon=""
            outlined
            flat
            filled
            autocomplete="chrome-off"
            dense
            return-object
            item-text="name"
            item-value="name"
            @change="changeProvince"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- CITY -->
          <v-combobox
            :label="$t('register.user.city')"
            v-model="internalValue.city"
            :rules="[requiredRule()]"
            :items="getCitiesList(internalValue.city)"
            append-icon=""
            outlined
            flat
            filled
            dense
            item-text="name"
            item-value="name"
            autocomplete="chrome-off"
            @change="changeCity"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- POSTAL CODE -->
          <v-combobox
            :label="$t('register.user.postalCode')"
            v-model="internalValue.postalcode"
            :rules="[requiredRule()]"
            :items="getCapsList(internalValue.postalcode)"
            append-icon=""
            outlined
            flat
            filled
            dense
            item-text="name"
            item-value="name"
            autocomplete="chrome-off"
            @change="changeCap"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="9">
          <!-- ADDRESS1 -->
          <v-combobox
            :label="$t('register.user.address')"
            v-model="internalValue.address1"
            :rules="[requiredRule()]"
            :items="getAddressesList(internalValue.address1)"
            append-icon=""
            outlined
            flat
            filled
            autocomplete="chrome-off"
            dense
            item-text="name"
            item-value="name"
            @change="changeAddress1"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- ADDRESS NUMBER -->
          <v-text-field
            :label="$t('register.user.addressNumber')"
            v-model="internalValue.addressNumber"
            :rules="[requiredRule()]"
            outlined
            flat
            filled
            dense
          ></v-text-field>
        </v-col>

        <!-- INTERNO, apartmentNumber -->
        <!-- <v-col cols="12" sm="3">
          <v-text-field
            :label="$t('register.user.apartmentNumber')"
            v-model="internalValue.apartmentNumber"
            outlined
            flat
            filled
            dense
            @change="validate"
          ></v-text-field>
        </v-col> -->

        <v-col cols="12" sm="6">
          <!-- telefono -->
          <v-text-field
            :label="$t('profile.addresses.homePhone')"
            v-model="internalValue.referencePhoneNumber"
            :rules="genereicPhoneRules"
            outlined
            flat
            filled
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- cellulare -->
          <v-text-field
            :label="$t('profile.addresses.mobilePhone')"
            v-model="internalValue.referenceMobileNumber"
            :rules="phoneRules"
            required
            outlined
            flat
            filled
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>

      <span class="d-block mt-7 mb-4 text-h4">{{
        $t("profile.addresses.complete")
      }}</span>
      <v-row class="grey lighten-2 rounded">
        <v-col cols="12" sm="6">
          <v-text-field
            :label="$t('profile.addresses.type')"
            v-model="internalValue.addressName"
            :rules="[requiredRule()]"
            required
            outlined
            flat
            filled
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- nome sul citofono -->
          <v-text-field
            :label="$t('profile.addresses.doorbellName')"
            v-model="internalValue.doorbellName"
            :rules="[isName()]"
            outlined
            flat
            filled
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <!-- Piano -->
          <v-text-field
            :label="$t('profile.addresses.floor')"
            v-model="internalValue.floor"
            outlined
            flat
            filled
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <!-- ascensore -->
          <!-- <v-switch
            v-model="internalValue.elevator"
            :true-value="true"
            :false-value="false"
            inset
            :label="$t('profile.addresses.elevator')"
            dense
            class="mt-0"
            hide-details
          ></v-switch> -->
          <v-radio-group
            inline
            v-model="internalValue.elevator"
            :label="$t('profile.addresses.elevator')"
          >
            <v-radio label="Sì" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <!-- Altre info, addressNote -->
          <v-text-field
            :label="$t('profile.addresses.other')"
            v-model="internalValue.addressNote"
            outlined
            flat
            filled
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>

      <label v-html="$t('profile.mandatoryField')" class="d-block mt-7" />
      <div class="d-flex justify-end my-3">
        <v-btn color="primary" type="submit" large depressed>
          {{ $t("profile.addresses.save") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<style lang="scss">
.address-edit-container {
}
</style>
<script>
import BaskoCustomService from "@/service/baskoCustomService";
import DeliveryService from "~/service/deliveryService";
import StoreService from "~/service/storeService";

import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import {
  requiredValue,
  isGenericPhone,
  isMobilePhone,
  genericPeopleName
} from "~/validator/validationRules";

export default {
  name: "AddressEdit",
  props: {
    isRider: { type: Boolean, required: false }
  },
  data() {
    return {
      valid: false,
      // validRequiredValues: false,
      lazy: true,
      error: null,
      showAutomplete: true,
      homeDeliveryAvailable: null,
      googleEnabled: false,
      checkAddressDisabled: true,
      isReadOnly: true,
      isFilled: true,
      internalValue: {
        addressName: null,
        doorbellName: null,
        city: null,
        addressNote: null,
        postalcode: null,
        province: null,
        addressNumber: null,
        address1: null,
        floor: null,
        referencePhoneNumber: null,
        elevator: null,
        longitude: null,
        latitude: null,
        country: null,
        apartmentNumber: null,
        doorbellNumber: null
      },
      address: null,
      provinceList: [],
      cityList: [],
      capList: [],
      addressList: [],
      postalcodeList: [],
      requiredRule: requiredValue,
      genereicPhoneRules: [isGenericPhone()],
      phoneRules: [requiredValue(), isMobilePhone()],
      isName: genericPeopleName,
      addressGeoCoded: null,
      validateAddress: false,
      missingAddressData: false,
      missingAddressDataMessage: null,
      addressDataInfo: [
        { field: "city", message: "città", required: true },
        { field: "address1", message: "indirizzo", required: true },
        { field: "postalcode", message: "CAP", required: true },
        { field: "province", message: "provincia", required: true },
        { field: "addressNumber", message: "civico", required: true }
      ],
      // floorItems: [
      //   "Piano terra",
      //   "1",
      //   "2",
      //   "3",
      //   "4",
      //   "5",
      //   "6",
      //   "Oltre il sesto"
      // ],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null
    };
  },
  directives: { mask },
  computed: {
    enableSave() {
      return !this.valid;
    },
    titleSection() {
      return this.internalValue.addressId
        ? this.$t("profile.addresses.editAddressTitle", [
            this.internalValue.addressName
          ])
        : this.$t("profile.addresses.newAddressTitle");
    }
  },
  methods: {
    ...mapActions({
      setAddress: "cart/setAddress"
    }),
    async getAddress(addressId) {
      this.internalValue = await DeliveryService.getAddresById(addressId);

      // init city list
      let provinceSelected = this.provinceList.filter(
        province => province.name == this.internalValue.province
      );
      if (provinceSelected.length > 0) {
        await this.getCityList(provinceSelected[0].itemId);
      }

      // init cap list
    },
    async saveAddress() {
      let vm = this;
      if (this.$refs.form.validate()) {
        var addressUpdated = await DeliveryService.updateAddress(
          vm.internalValue
        );
        let isNewAddressSetted = false;
        let mustSetNewAddressAsHomeDelivery = false;
        if (addressUpdated) {
          if (this.isRider) {
            let riderAddresses = await StoreService.getAddressesByDeliveryService(
              4
            );
            let newAddressIsRiderAddress = riderAddresses.find(
              address => address.addressId == addressUpdated.addressId
            );
            if (newAddressIsRiderAddress) {
              await vm.setAddress({
                addressType: "home",
                addressId: addressUpdated.addressId,
                deliveryServiceId: 4
              });
              isNewAddressSetted = true;
            } else {
              let popupRes = await this.$dialog.confirm({
                text: this.$t("profile.address.noRiderAddress")
              });
              if (popupRes) {
                mustSetNewAddressAsHomeDelivery = true;
              }
            }
          } else {
            mustSetNewAddressAsHomeDelivery = true;
          }
        }
        if (mustSetNewAddressAsHomeDelivery) {
          await vm.setAddress({
            addressType: "home",
            addressId: addressUpdated.addressId
          });
          isNewAddressSetted = true;
        }
        if (isNewAddressSetted) {
          vm.$emit("save");
          vm.$router.push({
            name: "TimeslotSelectorView",
            query: {
              deliveryServiceId: this.isRider ? 4 : 2
            }
          });
        } else {
          vm.$router.push({
            name: "Addresses"
          });
        }
      }
    },
    back() {
      this.resetForm();
      this.$emit("back");
    },

    // address
    changeProvince(province) {
      this.internalValue.province = province.name;
      this.internalValue.city = null;
      this.internalValue.postalcode = null;
      this.internalValue.address1 = null;
      this.cityList = [];
      this.capList = [];
      this.addressList = [];
      this.selectedCityId = null;
      this.getCityList(province.itemId);
    },
    changeCity(city) {
      this.internalValue.city = city.name;
      this.internalValue.postalcode = null;
      this.internalValue.address1 = null;
      this.capList = [];
      this.addressList = [];
      this.selectedCityId = city.itemId;
      this.getCapList(city.itemId);
    },
    changeCap(cap) {
      this.internalValue.postalcode = cap.name;
      this.internalValue.address1 = null;
      this.addressList = [];
      this.getAddressList(cap.itemId);
    },
    changeAddress1(address1) {
      this.internalValue.address1 = address1.name;
    },
    async getAddressList(capId) {
      this.addressList = await BaskoCustomService.streetList(
        this.selectedCityId,
        capId,
        true
      );
    },
    async getCityList(provinceId) {
      this.cityList = await BaskoCustomService.cityList(provinceId, true);
    },
    async getCapList(capId) {
      this.capList = await BaskoCustomService.postcodeList(capId, true);
    },
    getCitiesList(text) {
      let _this = this;
      var filteredCities = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.cityList.length; i++) {
          var formattedCityName = _this.cityList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedCityName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedCityName.includes(formattedText))
          ) {
            filteredCities.push(_this.cityList[i]);
          }
        }
      } else {
        filteredCities = _this.cityList;
      }
      return filteredCities;
    },
    getAddressesList(text) {
      let _this = this;
      var filteredAddresses = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.addressList.length; i++) {
          var formattedAddressName = _this.addressList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedAddressName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedAddressName.includes(formattedText))
          ) {
            filteredAddresses.push(_this.addressList[i]);
          }
        }
      } else {
        filteredAddresses = _this.addressList;
      }
      return filteredAddresses;
    },
    getCapsList(text) {
      let _this = this;
      var filteredCaps = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.capList.length; i++) {
          var formattedCapName = _this.capList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedCapName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedCapName.includes(formattedText))
          ) {
            filteredCaps.push(_this.capList[i]);
          }
        }
      } else {
        filteredCaps = _this.capList;
      }
      return filteredCaps;
    },
    getProvincesList(text) {
      let _this = this;
      var filteredProvinces = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.provinceList.length; i++) {
          var formattedProvinceName = _this.provinceList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedProvinceName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedProvinceName.includes(formattedText))
          ) {
            filteredProvinces.push(_this.provinceList[i]);
          }
        }
      } else {
        filteredProvinces = _this.provinceList;
      }
      return filteredProvinces;
    },
    getProvinces(countryId = 1) {
      let _this = this;
      if (_this.provinceList.length < 1) {
        BaskoCustomService.provinceList(countryId, true).then(function(
          results
        ) {
          _this.provinceList = results;
        });
      }
    }
  },
  async mounted() {
    await this.getProvinces(1);
    if (this.$route.params.addressId) {
      await this.getAddress(this.$route.params.addressId);
    }
  }
};
</script>
